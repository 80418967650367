/* Typography */

@font-face {
	font-family: 'Canela Web';
	font-weight: var(--font-weight-regular);
	font-style: normal;
	font-display: swap;
	src: local('Canela'),
		url('../../fonts/Canela-Light-Web.woff2') format('woff2'),
		url('../../fonts/Canela-Light-Web.woff') format('woff');
}

@font-face {
	font-family: 'plusjakarta';
	font-weight: 100 1000;
	font-style: normal;
	font-display: swap;
	src: local('plusjakarta'),
		url('../../fonts/plusjakarta-variablefont.woff2') format('woff2-variations'),
		/* will be the standard and works in Safari now */
		url('../../fonts/plusjakarta-variablefont.woff2') format('woff2')
		/* for the other supporting browsers */
}

html > body {
    font-size: var(--font-size-4);
    font-family: $font-stack-text;
    color: var(--gray-90);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: $font-semibold;
    color: var(--gray-90);
}

h1, h2, h3, .h1, .h2, .h3 {
    font-family: $font-stack-display;
    font-weight: $font-regular;
}

.h4, .h5, .h6 {
    font-family: $font-stack-text;
    font-weight: $font-semibold;
}

h1, .h1 {
    font-size: 3.25rem; // IE fallback
	line-height: 3.75rem; // IE fallback
	margin-bottom: 32px; // IE fallback
    
	font-size: clamp(var(--font-size-8), 3vw + 1rem, var(--font-size-10));
	line-height: clamp(var(--line-height-7), 3vw + 1rem, var(--line-height-8));
	margin-bottom: clamp(var(--space-5), 3vw + 1rem, var(--space-9));
	
}

h2, .h2 {
	font-size: 2.5rem; // IE fallback
	line-height: 2.75rem; // IE fallback
    margin-bottom: 20px; // IE fallback
    
	font-size: clamp(var(--font-size-7), 2vw + 1rem, var(--font-size-9));
	line-height: clamp(var(--line-height-6), 2vw + 1rem, var(--line-height-7));
	margin-bottom: clamp(var(--space-4), 2vw + 20px, var(--space-8));

}

h3, .h3 {
    font-size: 2rem; // IE fallback
	line-height: 2.25rem; // IE fallback
	margin-bottom: 16px; // IE fallback
    
	font-size: clamp(var(--font-size-7), 1vw + 1rem, var(--font-size-8));
	line-height: clamp(var(--line-height-6), 1vw + 1rem, var(--line-height-7));
	margin-bottom: clamp(var(--space-3), 1vw + 16px, var(--space-6));
	
}

h4, .h4 {
    
	font-size: 1.5rem; // IE fallback
	line-height: 2rem; // IE fallback
	margin-bottom: 14px; // IE fallback
    
	font-size: clamp(var(--font-size-5), 1vw + 1rem, var(--font-size-7));
	line-height: clamp(var(--line-height-5), 1vw + 1rem, var(--line-height-6));
	margin-bottom: clamp(var(--space-2), 1vw + 14px, var(--space-5));

}

h5, .h5 {

	font-size: 1.25rem; // IE fallback
	line-height: 1.5rem; // IE fallback
	margin-bottom: 12px; // IE fallback
    
	font-size: clamp(var(--font-size-4), 1vw + 1rem, var(--font-size-6));
	line-height: clamp(var(--line-height-4), 1vw + 1rem, var(--line-height-5));
	margin-bottom: clamp(var(--space-1), 1vw + 12px, var(--space-4));

}

h6, .h6 {

	font-size: 0.875rem; // IE fallback
	line-height: 1.5rem; // IE fallback
	margin-bottom: 8px; // IE fallback
    
}

p, .p, .t-body {
    font-size: var(--font-size-4);
	font-family: $font-stack-text;
	// font-size: clamp(var(--font-size-3), 40vw, var(--font-size-5));
    line-height: rem(24);
    color: var(--gray-80);
	// width: clamp(45ch, 50%, 75ch);
	// font-size:#{"max(var(--font-size-3),1.025vw)"};
	font-size: clamp(var(--font-size-3), 1vw, var(--font-size-5));
}

small {
    font-size: var(--font-size-2);
    line-height: rem(16);
}

strong {
    font-weight: $font-semibold;
}

a {
	color: var(--gray-90);
	font-weight: var(--font-weight-semibold);

	&:hover {
		text-decoration: none;
	}

}

.subtitle {
	font-family: $font-stack-text;
	font-size: var(--font-size-5);
	line-height: rem(28);
}

.t-subtitle-2 {
	font-family: $font-stack-text;
	font-size: var(--font-size-3);
	font-weight: var(--font-weight-semibold);
	line-height: rem(24);
	letter-spacing: rem(0.2);
}

.p-small {
	font-family: $font-stack-text;
	font-size: var(--font-size-3);
	line-height: rem(22);
}

caption {
	font-family: $font-stack-text;
	font-size: var(--font-size-2);
	line-height: rem(20);
	letter-spacing: rem(1);
}

.overline {
	font-family: $font-stack-text;
	font-size: var(--font-size-1);
	line-height: rem(18);
	text-transform: uppercase;
	letter-spacing: rem(1.5);
}

.t-body-2 {
	font-family: $font-stack-text;
	font-weight: var(--font-weight-regular);
	font-size: var(--font-size-3);
	line-height: var(--line-height-2);
	color: var(--gray-70);
}

.t-body-3 {
	font-family: $font-stack-text;
	font-weight: var(--font-weight-regular);
	font-size: var(--font-size-2);
	line-height: var(--line-height-1);
	color: var(--gray-70);
}

.t-link {
	font-family: $font-stack-text;
	text-decoration: underline;
	color: var(--gray-70);
	font-size: var(--font-size-3);
	// For usage on buttons
	-webkit-appearance: none;
	appearance: none;
    border: none;
    background-color: transparent;
}

.t-link .icon {
	width: var(--space-3);
	height: var(--space-3);
	fill: var(--gray-90);
	margin-right: var(--space-2);
}