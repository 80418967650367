/* ===========================
Dotation Info
=========================== */

.c-dotation-box {
    border-top: 1px solid var(--gray-20);
}

.c-dotation-box .page-wrap {
    display: flex;
    flex-direction: column;
    padding: var(--space-2) 0;

    @include breakpoint(min-768) {
        flex-direction: row;
        align-items: center;
    }
}

.c-dotation-box__copy {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;

    @include breakpoint(min-768) {
        flex: 0 0 55%;
    }
}

.c-dotation-box .c-dotation-box__logos {
    list-style-type: none;
    display: flex;
    flex: 0 0 100%;
    padding: 0;
    flex-direction: row;
    align-items: center;

    @include breakpoint(min-768) {
        margin-left: auto;
        flex: 0 0 40%;
    }
}

.c-dotation-box .c-dotation-box__logos-item {
    width: rem(160);
}

.c-dotation-box .c-dotation-box__logos-item svg {
    height: 100%;
    max-height: rem(60);
}

.c-dotation-box .c-dotation-box__logos-item--eu {
    width: rem(200);
    margin-top: rem(20);
}

.c-dotation-box .c-dotation-box__logos-item--eu svg {
    height: 100%;
    max-height: rem(140);
}

.c-dotation-box .c-dotation-box__logos-item--npo {
    width: rem(180);
}

.c-dotation-box .c-dotation-box__logos-item--npo svg {
    max-height: rem(80);
}