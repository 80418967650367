/* ===========================
Add to cart cross sell carousel
=========================== */
.c-add-to-cart-cross-sell-carousel {
	width: 100%;
	overflow-x: auto;
	display: flex;
	gap: var(--space-2);
	margin: var(--space-0);
	padding: var(--space-0);
	list-style-type: none;
}

.c-add-to-cart-cross-sell-carousel__item {
	display: inline-flex;
	flex-direction: column;
	gap: var(--space-2);
	font-size: var(--font-size-3);
	line-height: var(--line-height-2);
}

.c-add-to-cart-cross-sell-carousel__image {
	width: rem(152);
	height: rem(152);
	position: relative;

	picture::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(var(--taupe-500),.03);
	}

	img {
		width: 100%;
		height: auto;
		display: block;
	}

	.btn {
		position: absolute;
		right: var(--space-1);
		bottom: var(--space-1);
	}
}

.c-add-to-cart-cross-sell-carousel__name {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-weight: var(--font-weight-regular);
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.c-add-to-cart-cross-sell-carousel__price {
	font-weight: var(--font-weight-semibold);
}

.c-add-to-cart-cross-sell-carousel__price--sale {
	color: var(--paradise-pink);
}

.c-add-to-cart-cross-sell-carousel__price-origin {
	color: var(--gray-50);
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-regular);
	text-decoration: line-through;
}