/* ===========================
Add to cart product detail
=========================== */
.c-add-to-cart-product-detail {
	display: flex;
	flex-direction: column;
	gap: var(--space-4);
	padding: var(--space-4) var(--space-0);

	@include breakpoint(min-640) {
		padding: var(--space-6) var(--space-0);
    }
}

.c-add-to-cart-product-detail__image-gallery {
	overflow-x: scroll;
	display: flex;
	gap: var(--space-2);
	padding: var(--space-0) var(--space-4);

	@include breakpoint(min-640) {
		padding: var(--space-0) var(--space-6);
    }

	picture {
		height: rem(240);
		position: relative;
	}

	picture::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(var(--taupe-500),.03);
	}

	img {
		width: auto;
		height: 100%;
		display: block;
		margin: auto;
	}
}

.c-add-to-cart-product-detail__content {
	display: flex;
	flex-direction: column;
	gap: var(--space-8);
	padding: var(--space-0) var(--space-4);

	@include breakpoint(min-640) {
		padding: var(--space-0) var(--space-6);
    }
}

.c-add-to-cart-product-detail__basic-info {
	display: flex;
	flex-direction: column;
	gap: var(--space-2);
}

.c-add-to-cart-product-detail__name {
	font-size: var(--font-size-5);
	line-height: var(--line-height-4);

}

.c-add-to-cart-product-detail__desc {
	font-size: var(--font-size-3);
	line-height: var(--line-height-2);
	margin: var(--space-0);
	
}

// TODO: Maybe unify in futute (Selectbox component)?
.c-add-to-cart-product-detail__option .selectbox-label {
	display: flex;
    flex: 0 0 100%;
}

.c-add-to-cart-product-detail__option .selectbox-label a {
	color: var(--gray-70);
    display: inline-block;
    font-size: var(--font-size-3);
	line-height: var(--line-height-2);
    font-weight: var(--font-weight-regular);
    margin-left: auto;
    padding-left: var(--space-5);
    position: relative;
}

.c-add-to-cart-product-detail__option .icon-question {
	fill: var(--gray-30);
    height: 1rem;
    left: 0;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem;
}

.c-add-to-cart-product-detail__option .selectbox {
	width: 100%;
    margin-bottom:  var(--space-1);
}
// TODO: Maybe unify in futute (Selectbox component)? END

.c-add-to-cart-product-detail__footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--space-2);
}


.c-add-to-cart-product-detail__price {
	font-size: var(--font-size-6);
	line-height: var(--line-height-4);
	font-weight: var(--font-weight-semibold);
}

.c-add-to-cart-product-detail__price--sale {
	color: var(--paradise-pink);
}

.c-add-to-cart-product-detail__price-origin {
	color: var(--gray-50);
	font-size: var(--font-size-5);
	font-weight: var(--font-weight-regular);
	text-decoration: line-through;
}

.c-add-to-cart-product-detail__vat-info {
	display: block;
	color: var(--gray-70);
	margin: var(--space-0);
	font-size: var(--font-size-3);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-regular);
}