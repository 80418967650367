/* ===========================
Cookie modal
=========================== */
.c-cookie-modal {
    position: fixed;
    top: 0;
    z-index: $z-index-12;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;

    @include breakpoint(min-640) {
        align-items: baseline;
    }
}

.c-cookie-modal__overlay {
    background-color: var(--black) !important;
    height: 100% !important;
    left: rem(0) !important;
    opacity: 0.6 !important;
    position: fixed !important;
    top: rem(0) !important;
    width: 100% !important;
    z-index: -1 !important;
}

.c-cookie-modal__close-wrapper {
    display: flex;
    justify-content: flex-end;
}

.c-cookie-modal__close {
    flex: 0 0 auto;
    border-radius: var(--border-radius-half);
    background-color: var(--white);
    width: rem(36);
    height: rem(36);
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow .2s $ease-in-out-quad, background-color .2s $ease-in-out-quad;

    .icon {
        flex: 0 0 auto;
        fill: var(--gray-70);
        width: rem(14);
        height: rem(14);
        transition: fill .2s $ease-in-out-quad;
    }

    &:hover {
        background-color: var(--gray-20);

        .icon {
            fill: var(--gray-60);
        }
    }

    &:focus, &:focus-visible {
        outline: none;
        box-shadow: inset 0 0 0 rem(1) var(--gray-60), 0 0 0 rem(3) var(--gray-20);
    }
}

.c-cookie-modal__content {
    border-radius: rem(6);
    background-color: var(--white);
    display: flex;
    flex-wrap: wrap;
    margin: rem(8);
    padding: rem(16);
    width: calc(100% - #{rem(16)});
    box-shadow: 0 0 rem(20) rgba(var(--black),.2);

    @include breakpoint(min-480) {
        margin: rem(16);
        padding: rem(32);
        width: calc(100% - #{rem(32)});
    }
    
    @include breakpoint(min-640) {
        max-width: rem(600);
        margin: auto auto;
    }
}

.c-cookie-modal__content .c-cookie-modal__close-wrapper {
    margin-left: auto;
}


.c-cookie-modal.hidden {
    display: none;
}

.c-cookie-modal strong {
    font-family: $font-stack-text;
    font-weight: var(--font-weight-semibold);
    margin-bottom: rem(8);
    display: inline-block;

    @include breakpoint(min-640) {
        margin-bottom: rem(16);
    }
    
}

.c-cookie-modal .h1 {
    font-size: var(--font-size-6);
    line-height: rem(24);

    @include breakpoint(min-640) {
        font-size: var(--font-size-7);
        line-height: rem(32);
    }
}

.c-cookie-modal .h2 {
    font-size: var(--font-size-5);
    line-height: rem(24);
    margin-bottom: rem(16);

    @include breakpoint(min-640) {
        font-size: var(--font-size-6);
    }
}

.c-cookie-modal .h3 {
    font-size: var(--font-size-4);
    line-height: rem(20);
    margin: 0;

    @include breakpoint(min-640) {
        margin: 0;
    }
}

.c-cookie-modal p {
    font-size: var(--font-size-3);
    line-height: rem(20);
    margin: 0;
}

.c-cookie-modal__cookie-settings {
    margin: rem(24) 0 0 0;

    @include breakpoint(min-640) {
        margin: rem(36) 0 0 0;
    }
}

.c-cookie-modal__cookie-settings-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: rem(-16);
    list-style-type: none;
}

.c-cookie-modal__cookie-settings-item {
    margin: rem(16);
}

.c-cookie-modal__cookie-settings-toggle {
    display: inline-flex;
    cursor: pointer;
}

.c-cookie-modal__cookie-settings-toggle input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.c-cookie-modal__cookie-settings-toggle input:checked + .c-cookie-modal__cookie-settings-toggle-slider {
    background-color: #0C5C18;
}

.c-cookie-modal__cookie-settings-toggle input:checked + .c-cookie-modal__cookie-settings-toggle-slider::before {
  transform: translateX(20px);
}

.c-cookie-modal__cookie-settings-toggle input:disabled  + .c-cookie-modal__cookie-settings-toggle-slider {
    background-color: var(--gray-30);
    cursor: not-allowed;
}

.c-cookie-modal__cookie-settings-toggle input:disabled  + .c-cookie-modal__cookie-settings-toggle-slider + .c-cookie-modal__cookie-settings-toggle-label {
    cursor: not-allowed;
}

.c-cookie-modal__cookie-settings-toggle-slider {
    display: inline-flex;
    background-color: var(--gray-50);
    width: rem(48);
    height: rem(28);
    padding: rem(4);
    border-radius: rem(14);
    flex: 0 0 auto;
    margin-right: rem(16);
    transition: background-color .2s ease-in-out;
}

.c-cookie-modal__cookie-settings-toggle-slider::before {
    content: "";
    display: inline-block;
    background-color: var(--white);
    width: rem(20);
    height: rem(20);
    border-radius: 100%;
    transition: transform .2s ease-in-out;
}

.c-cookie-modal__btn-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1 1 100%;
    margin: rem(20) rem(-4) rem(-4) rem(-4);

    @include breakpoint(min-480) {
        margin: rem(20) rem(-8) rem(-8) rem(-8);
    }

    @include breakpoint(min-640) {
        margin: rem(28) rem(-8) rem(-8) rem(-8);
        flex-direction: row-reverse;
        justify-content: flex-start;
    }
}

.c-cookie-modal__btn {
    margin: rem(4);
    padding: rem(4) rem(20);
    height: rem(52);
    border-radius: rem(26);
    width: 100%;
    font-size: var(--font-size-4);
    line-height: rem(20);
    text-transform: uppercase;
    font-weight: var(--font-weight-semibold);
    border: none;
    transition: background-color .2s ease-in-out;

    @include breakpoint(min-480) {
        margin: rem(8);
    }

    @include breakpoint(min-640) {
        width: auto;
    }
}

.c-cookie-modal__btn--primary {
    background-color: var(--gray-90);
    color: var(--white);

    &:hover {
        background-color: var(--gray-80);
    }
}

.c-cookie-modal__btn--secondary {
    background-color: var(--white);
    border: rem(2) solid var(--gray-90);
    color: var(--gray-90);

    &:hover {
        background-color: var(--gray-30);
    }
}

.c-cookie-modal__btn--fullwidth {
    width: 100%;
}

.c-cookie-modal__link {
    width: 100%;
    margin: rem(4);
    padding: rem(4) rem(20);
    font-size: var(--font-size-3);
    line-height: rem(20);
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);
    border: none;
    background-color: transparent;
    color: var(--gray-90);

    @include breakpoint(min-480) {
        margin: rem(8);
    }

    @include breakpoint(min-640) {
        width: auto;
    }
}