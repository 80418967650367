/* ===========================
Forms
=========================== */

input[type="text"],
input[type="email"],
input[type="search"],
input[type="url"],
input[type="password"],
input[type="tel"],
input[type="number"] {
}

.input {
	background: $white;
	border: rem(1) solid $gray-50;
	border-radius: rem(2);

	&--large {
		font-size: var(--font-size-4);
		padding: rem(16);
		line-height: rem(20);
	}

	&--medium {
		font-size: var(--font-size-4);
		padding: rem(12);
		line-height: rem(16);
	}

	&--small {
		font-size: var(--font-size-4);
		padding: rem(8);
		line-height: rem(16);
	}

	&--left-aligned {
		border-radius: rem(2) 0 0 rem(2);
	}

	&--right-aligned {
		border-radius: 0 rem(2) rem(2) 0;
	}

	&--error {
		border-color: $paradise-pink;
		border-radius: 0 0 rem(2) rem(2);
	}

	&:disabled {
		background: $gray-10;
		border-color: $gray-40;
		color: $gray-50;
	}
}

.textarea {
	background: $white;
	border: rem(1) solid $gray-50;
	border-radius: rem(2);

	&--large {
		font-size: var(--font-size-4);
		padding: rem(16);
		line-height: rem(20);
	}
}

.selectbox {
	background: $gray-10;
	width: rem(180);
	border-radius: rem(2);
	display: inline-block;
	position: relative;

	&__select {
		background: transparent;
		border: none;
		font-size: var(--font-size-4);
		line-height: rem(16);
		font-weight: $font-semibold;
		color: $gray-70;
		display: inline-block;
		height: rem(44);
		padding: rem(14) rem(32) rem(14) rem(14);
		width: 100%;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.icon {
		width: rem(8);
		height: rem(8);
		position: absolute;
		right: rem(16);
		top: 50%;
		margin-top: calc(rem(4) * -1);
	}

	&--error {
		box-shadow: inset 0 0 0 rem(2) $paradise-pink;
	}
}

.selectbox-label {
	display: block;
	color: $gray-70;
	font-size: var(--font-size-3);
	line-height: rem(16);
	font-weight: $font-semibold;
	margin-bottom: rem(4);
}

.c-selectbox-label {
	&--medium {
		margin-bottom: rem(16);
	}
}

.c-input-label,
.c-textarea-label {
	&__label {
		display: block;
		font-weight: $font-semibold;
		color: $gray-90;

		span {
			font-weight: $font-regular;
			color: $gray-40;
			margin-left: rem(4);
		}
	}

	&--large &__label {
		font-size: var(--font-size-3);
		line-height: rem(16);
		margin-bottom: rem(4);
	}

	&__input {
		width: 100%;
	}

	&__textarea {
		width: 100%;
		height: rem(80);
	}

	&__suffix {
		background: $gray-10;
		border: rem(1) solid $gray-50;
		border-left: none;
		border-radius: 0 rem(2) rem(2) 0;
		color: $gray-80;
		text-transform: uppercase;
		font-weight: $font-semibold;

		&--large {
			font-size: var(--font-size-2);
			padding: rem(16);
			line-height: rem(20);
			min-width: rem(54);
		}
	}

	&__error {
		background-color: $paradise-pink;
		border-radius: rem(2) rem(2) 0 0;
		color: $white;
		font-size: var(--font-size-3);
		padding: rem(4) rem(8);
		display: block;
	}

	&__additional-note {
		color: $gray-60;
		font-size: var(--font-size-3);
		margin: rem(8) 0 0 0;
		display: block;
	}
}

.l-input-wrapper {
	display: flex;
}

.input-container {
	position: relative;
	display: block;
	cursor: pointer;
	font-weight: $font-semibold;
	color: $gray-90;
}

.input-container--disabled {
	cursor: initial;
	color: $gray-60;
}

.input-container--small {
	font-size: var(--font-size-3);
	padding-left: rem(24);
}

.input-container--small .input-container__indicator {
	width: rem(16);
	height: rem(16);
	margin-top: calc(rem(8) * -1);
}

.input-container--medium {
	font-size: var(--font-size-4);
	padding-left: rem(30);
}

.input-container--medium .input-container__indicator {
	width: rem(20);
	height: rem(20);
	margin-top: calc(rem(10) * -1);
}

.input-container--large {
	font-size: var(--font-size-6);
	padding-left: rem(40);
}

.input-container--large .input-container__indicator {
	width: rem(24);
	height: rem(24);
	margin-top: calc(rem(12) * -1);
}

.input-container--disabled .input-container__indicator {
	box-shadow: inset 0 0 0 rem(1) $gray-40;
	background: $gray-10;
}

.input-container__input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.input-container__indicator {
	position: absolute;
	top: 50%;
	left: 0;
	background: $white;
	border-radius: rem(2);
	box-shadow: inset 0 0 0 rem(1) $gray-60;
}

.input-container__indicator::after {
	position: absolute;
	display: none;
	content: "";
}

.input-container--checkbox .input-container__indicator::after {
	width: rem(3);
	height: rem(8);
	transform: rotate(45deg);
	border: solid $white;
	border-width: 0 rem(2) rem(2) 0;
}

.input-container--large.input-container--checkbox
	.input-container__indicator::after {
	top: rem(6);
	left: rem(10);
}

.input-container--medium.input-container--checkbox
	.input-container__indicator::after {
	top: rem(4);
	left: rem(8);
}

.input-container--small.input-container--checkbox
	.input-container__indicator::after {
	top: rem(2);
	left: rem(6);
}

.input-container--radio .input-container__indicator::after {
	border-radius: 50%;
	background: $white;
}

.input-container--large.input-container--radio
	.input-container__indicator::after {
	top: rem(6);
	left: rem(6);
	width: rem(12);
	height: rem(12);
}

.input-container--medium.input-container--radio
	.input-container__indicator::after {
	top: rem(5);
	left: rem(5);
	width: rem(10);
	height: rem(10);
}

.input-container--small.input-container--radio
	.input-container__indicator::after {
	top: rem(4);
	left: rem(4);
	width: rem(8);
	height: rem(8);
}

.input-container--checkbox
	.input-container__input:disabled
	~ .input-container__indicator::after {
	border-color: $gray-40;
}

.input-container--radio
	.input-container__input:disabled
	~ .input-container__indicator:after {
	background: $gray-40;
}

.input-container--radio .input-container__indicator {
	border-radius: 50%;
}

.input-container:hover .input-container__input ~ .input-container__indicator,
.input-container .input-container__input:focus ~ .input-container__indicator {
	background: $gray-10;
	box-shadow: inset 0 0 0 rem(1) $gray-90;
}

.input-container .input-container__input:checked ~ .input-container__indicator {
	background: $gray-90;
	box-shadow: inset 0 0 0 rem(1) $gray-90;
}

.input-container
	.input-container__input:disabled
	~ .input-container__indicator {
	pointer-events: none;
	cursor: not-allowed;
	background: $gray-20;
	box-shadow: inset 0 0 0 rem(1) $gray-40;
}

.input-container
	.input-container__input:checked
	~ .input-container__indicator:after {
	display: block;
}

// Component - Radio Button

.c-radio-btn {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
}

.c-radio-btn input[type="radio"] {
	-webkit-appearance: none;
	appearance: none;
	/* For iOS < 15 to remove gradient background */
	background-color: var(--white);
	/* Not removed via appearance */
	margin: 0;
	color: currentColor;
	width: var(--space-5);
	height: var(--space-5);
	border: var(--space-05) solid var(--gray-50);
	border-radius: 50%;
	/* Improve input vs. label aligmnet */
	transform: translateY(0.125em);
	display: grid;
	place-content: center;
	position: absolute;
}

.c-radio-btn input[type="radio"]:checked,
.c-radio-btn input[type="radio"]:focus {
	border-color: rgba(var(--bd-500), 1);
}

.c-radio-btn input[type="radio"]::before {
	content: "";
	width: var(--space-2);
	height: var(--space-2);
	border-radius: 50%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em rgba(var(--bd-600), 1);

	/* Windows High Contrast Mode */
	background-color: CanvasText;
}

.c-radio-btn input[type="radio"]:checked::before {
	transform: scale(1);
}

.c-radio-btn input[type="radio"]:focus {
	outline: var(--space-05) solid rgba(var(--bd-600), 0.5);
	outline-offset: 0;
}

.c-radio-btn__label {
	display: flex;
	width: 100%;
	padding-left: var(--space-8);
}

.c-radio-btn__label svg {
	margin-left: var(--space-2);
	max-width: var(--space-20);
	max-height: var(--space-5);
	width: auto;
	height: 100%;
}

.c-radio-btn__label span {
	margin-left: auto;
}

.c-radio-btn__helptext {
	padding-left: var(--space-8);
}

.c-form-control {
	font-family: system-ui, sans-serif;
	font-size: var(--font-size-3);
	font-weight: var(--font-weight-semibold);
	line-height: 1.1;
	display: grid;
	grid-template-columns: 1em auto;
	gap: 0.5em;
}

.c-form-control + .c-form-control {
	margin-top: 1em;
}

input[type="checkbox"] {
	appearance: none;
	/* For iOS < 15 to remove gradient background */
	background-color: var(--white);
	/* Not removed via appearance */
	margin: 0;
	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid currentColor;
	border-radius: 0.15em;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;
}

input[type="checkbox"]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em rgba(var(--bd-600));
	/* Windows High Contrast Mode */

	background-color: CanvasText;

	transform-origin: bottom left;
	// clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	clip-path: polygon(0 0, 100% 0, 100% 50%, 37.5% 50%, 37.5% 100%, 0 100%);
}

input[type="checkbox"]:checked::before {
	transform: scale(1);
}

@import "forms/amount-input";
