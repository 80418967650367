/* ===========================
Header
=========================== */
.c-header {
	background: $white;
	position: relative;
	z-index: $z-index-10;	
}

.c-header--shadow {
	box-shadow: 0 rem(1) rem(20) 0 rgba(26,22,18,0.15);
}

.c-header--cart {
	@include breakpoint(min-960){
		box-shadow: 0 rem(1) 0 0 $gray-20;
	}
}

.c-header--cart-final {
	@include breakpoint(min-960){
		box-shadow: 0 rem(1) rem(20) 0 rgba(26,22,18,0.15);
	}

}

.c-header__nav {
	position: static;
	display: flex;
	flex-wrap: wrap;
}

.c-header__top-row {
	display: flex;
	width: 100%;
	padding: rem(8) 0;

	@include breakpoint(min-640) {
		padding: rem(16) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(32) 0 rem(28);
	}
}

.c-header__logo {
	display: block;
	position: absolute;
	left: 50%;
	margin-left: - rem(58);
	top: rem(18);
	transition: opacity .3s $ease-in-out-quad, visibility .3s $ease-in-out-quad;

	@include breakpoint(min-640) {
		top: rem(22);
		margin-left: - rem(82);
	}
	
	@include breakpoint(min-960) {
		top: rem(32);
		margin-left: - rem(90);
	}

	.brilianty-logo {
		fill: $gray-90;
		display: block;
		width: rem(116);
		height: rem(28);

		@include breakpoint(min-640) {
			width: rem(164);
			height: rem(40);
		}

		@include breakpoint(min-1280) {
			width: rem(160);
			height: rem(60);
		}
	}
}

.c-header__logo[hidden] {
	opacity: 0;
	visibility: hidden;
	display: block;
}

.c-header__menu-icon-label {
	display: none;
}

.c-header__main-nav {
	display: none;
	list-style-type: none;
	margin: 0;
	padding: 0;

	@include breakpoint(min-960) {
		display: flex;
		justify-content: center;
		width: 100%;
		flex: 0 0 100%;
	}

	@include breakpoint(min-960) {
		width: auto;
		margin-top: 0;
	}
}

.c-header__main-nav-item {
	align-self: center;
	position: static;
	padding-bottom: rem(12);
	padding: 0 rem(16);
	flex: 0 0 auto;

	@include breakpoint(min-1280) {
		padding: 0 rem(20);
	}

	&:hover > .header-megamenu {
		display: block;
	}
	
	> a, span {
		display: block;
		font-size: var(--font-size-2);
		line-height: rem(20);
		letter-spacing: rem(3);
		padding: rem(16) rem(4);
		color: $gray-90;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: $font-semibold;
		text-align: center;
		transition: box-shadow .2s $ease-in-out-quad;

		&:hover {
			box-shadow: inset 0 - rem(2) 0 $gray-90;
		}

		&:focus {
			/* TODO */
		}
	}
}

.c-header__cart-box {
	display: flex;
	list-style-type: none;
	padding: 0;
	margin: 0 - rem(4) 0 auto;
	transition: opacity .3s $ease-in-out-quad, visibility .3s $ease-in-out-quad;
	
	@include breakpoint(min-960) {
		position: static;
		margin: 0 - rem(12) 0 auto;
	}
}

.c-header__cart-box[hidden] {
	opacity: 0;
	visibility: hidden;
	display: block;
}

.c-header__cart-box-item {
	align-self: center;
	position: static;
	position: relative;
	margin: 0 rem(4);
	
	> a {
		text-decoration: none;
		font-weight: $font-semibold;
		color: $gray-90;
		display: block;
		position: relative;
	}
	
	.icon {
		width: rem(20);
		height: rem(20);
		position: absolute;
		top: rem(12);
		left: rem(12);
	}
}

.c-header__cart-box-item--phone {
	display: none;

	@include breakpoint(min-960) {
		display: block;
	}

	a {
		font-size: var(--font-size-3);
		line-height: rem(44);
		border-radius: rem(44);
		padding: 0 rem(16) 0 rem(40);
		transition: background-color .2s $ease-in-out-quad;

		&:hover {
			background-color: $gray-10;
		}
	}
}

.c-header__cart-box-item--account {
	display: none;

	@include breakpoint(min-960) {
		display: block;
	}

	a {
		width: rem(44);
		height: rem(44);
		font-size: var(--font-size-3);
		line-height: rem(44);
		border-radius: rem(44);
		transition: background-color .2s $ease-in-out-quad;
		
		@include breakpoint(min-1280) {
			width: auto;
			height: auto;
			padding: 0 rem(16) 0 rem(40);
		}

		&:hover {
			background-color: $gray-10;
		}
	} 

	[role="tooltip"] {
		display: none;
		
		@include breakpoint(min-1280) { 
			display: block; 
		}
	}
}

.c-header__cart-box-item--cart-content > a {
	border-radius: rem(44);
	width: rem(44);
	height: rem(44);
	padding: 0;
	transition: background-color .2s $ease-in-out-quad;

	&:hover {
		background-color: $gray-20;
	}

	@include breakpoint(min-1280) {
		font-size: var(--font-size-3);
		line-height: rem(44);
		background-color: $gray-10;
		width: auto;
		margin-right: 0;
		padding: 0 rem(36) 0 rem(44);
	}

	.icon-bag {
		left: rem(14);
		fill: $black;

		@include breakpoint(min-1280) {
			left: rem(16);
		}
	}

	.icon-chevron-bottom {
		display: none;
		
		@include breakpoint(min-1280) {
			width: rem(8);
			height: rem(8);
			right: rem(16);
			left: auto;
			top: rem(18);
			pointer-events: none;
			fill: $pink-70;
			display: block;
		}
	}

	.cart-count {
		position: absolute;
		top: 0;
		left: 50%;
		font-size: var(--font-size-1);
		line-height: rem(12);
		padding: rem(2) rem(5);
		text-align: center;
		min-width: rem(20);
		min-height: rem(16);
		border: rem(2) solid $white;
		border-radius: rem(16);
		background-color: $paradise-pink;
		color: $white;

		@include breakpoint(min-1280) {
			display: none;
		}
	}

	.cart-info {
		display: none;

		@include breakpoint(min-1280) {
			display: inline;
		}
	}
}

.c-header__mobile-menu-wrapper {
	margin-right: rem(4);
}

.c-header__mobile-menu {
	width: rem(40);
	position: relative;
	border: none;
	background-color: $white;
	line-height: rem(44);
	touch-action: manipulation;

	@include breakpoint(min-960) {
		display: none;
	}

	.icon-menu {
		position: absolute;
		left: 0;
		top: rem(20);
		width: rem(20);
		height: rem(20);
	}
}

.c-header__mobile-search {
	padding: 0 rem(16) 0 rem(28);
	position: relative;
	color: $gray-90;
	text-transform: uppercase;
	font-size: var(--font-size-3);
	line-height: rem(44);
	border: none;
	background-color: $white;
	touch-action: manipulation;
	transition: opacity .3s $ease-in-out-quad, visibility .3s $ease-in-out-quad;

	@include breakpoint(min-960) {
		display: none;
	}

	.icon-search {
		position: absolute;
		top: 50%;
		margin-top: - rem(10);
		left: 50%;
		margin-left: - rem(10);
		width: rem(20);
		height: rem(20);
	}
}

.c-header__mobile-search[hidden] {
	opacity: 0;
	visibility: hidden;
	display: block;
}

.c-header__config-back {
	padding: 0 rem(20) 0 rem(32);
	line-height: rem(32);

	.icon {
		fill: $pink-50;
	}
}

.c-header__search {
	display: block;
	position: absolute;
	top: rem(60);
	left: 0;
	width: 100%;
	background-color: $white;
	padding: rem(16) 4%;
	box-shadow: 0 rem(20) rem(20) 0 rgba(26,22,18,0.15);

	@include breakpoint(min-768) {
		top: rem(76);
	}

	@include breakpoint(min-960) {
		position: static;
		width: rem(300);
		box-shadow: none;
		padding: 0;
	}

	&[hidden] {
		display: none;

		@include breakpoint(min-960) {
			display: block;
		}
	}
}

.c-header__menu-icon {
	transition: filter .2s $ease-in-out-quad;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: hidden;
}

.c-header__menu-icon.active .c-header__menu-icon-inner,
.c-header__menu-icon.active .c-header__menu-icon-inner::before,
.c-header__menu-icon.active .c-header__menu-icon-inner::after {
	background-color: $gray-90;
	width: rem(20);
}

.c-header__menu-icon.active .c-header__menu-icon-inner {
	transform: translate3d(0, 7px, 0) rotate(45deg);
}

.c-header__menu-icon.active .c-header__menu-icon-inner::before {
	transform: rotate(-45deg) translate3d(-5.71429px, -3px, 0);
	opacity: 0;
}

.c-header__menu-icon.active .c-header__menu-icon-inner::after {
	transform: translate3d(0, -14px, 0) rotate(-90deg);
}

.c-header__menu-icon-box {
	width: rem(20);
	height: rem(12);
	display: inline-block;
	position: relative;
}

.c-header__menu-icon-inner {
	display: block;
	top: 50%;
	margin-top: rem(-7);
}

.c-header__menu-icon-inner,
.c-header__menu-icon-inner::before,
.c-header__menu-icon-inner::after {
	height: rem(2);
	background-color: $gray-90;
	border-radius: rem(2);
	position: absolute;
	transition: transform .2s $ease-in-out-quad;
}

.c-header__menu-icon-inner {
	width: rem(20);
}

.c-header__menu-icon-inner::before {
	width: rem(12);
}

.c-header__menu-icon-inner::after {
	width: rem(16);
}

.c-header__menu-icon-inner::before,
.c-header__menu-icon-inner:after {
	content: "";
	display: block;
}

.c-header__menu-icon-inner::before {
	top: 7px;
	transition: opacity .2s $ease-in-out-quad, transform .2s $ease-in-out-quad;
}

.c-header__menu-icon-inner::after {
	bottom: -5px;
	top: 14px; 
}

.l-mobile-menu-nav {
	overflow-y: auto;
	position: fixed;
	display: flex;
	flex-direction: column;
	flex: 1;
	top: rem(60);
	height: calc(100% - #{rem(60)});
	width: 100vw;
	margin: 0;
	padding: 0;
	left: 0;
	background-color: $white;
	padding: 0;
	line-height: rem(20);
	scroll-behavior: smooth;
	z-index: $z-index-10;
	will-change: transform;
	transition: opacity .3s $ease-in-out-quad, visibility .3s $ease-in-out-quad, transform .3s $ease-in-out-quad;

	@include breakpoint(min-640) {
		top: rem(76);
		height: calc(100% - #{rem(76)});
	}
}

.l-mobile-menu-nav[hidden] {
	visibility: hidden;
	opacity: 0;
	transform: translateX(-100%);
}

.c-mobile-menu-nav {
	padding: 0;
	margin: 0;
}

.c-mobile-menu-nav__section {
	flex: 1 1 100%;
	margin: 0 rem(12);
}

.c-mobile-menu-nav__section-label {
	cursor: pointer;
	font-weight: $font-semibold;
	letter-spacing: 1px;
	display: flex;
	align-items: center;
	padding: rem(22) rem(8);
	touch-action: manipulation;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.c-mobile-menu-nav__section-label:focus {
	outline: none;
}

.c-mobile-menu-nav__section-label.open::before {
	transform: rotate(90deg);
	opacity: 0;
}

.c-mobile-menu-nav__section-label.open::after {
	transform: rotate(90deg);
}

.c-mobile-menu-nav__section-label::before,
.c-mobile-menu-nav__section-label::after {
	position: absolute;
	background-color: $gray-70;
	border-radius: rem(1);
	content: "";
	display: block;
	transition: transform .15s $ease-in-quad, opacity .15s $ease-in-quad;
}

.c-mobile-menu-nav__section-label::before {
	width: rem(10);
	height: rem(2);
	right: rem(8);
}

.c-mobile-menu-nav__section-label::after {
	width: rem(2);
	height: rem(10);
	right: rem(12);
}

.c-mobile-menu-nav__section-nav {
	padding: 0;
	margin: 0;
	display: none;
}

.c-header__mobile-menu-item {
}

.c-header__mobile-menu-link {
	border-radius: rem(4);
	width: 100%;
	color: $gray-80;
	font-size: var(--font-size-3);
	font-weight: $font-regular;
	padding: rem(6) 0 rem(6) rem(24);
	margin: rem(8) 0;
	text-decoration: none;
	display: inline-flex;
	transition: background-color .2s $ease-in-out-quad;
}

.c-header__mobile-menu-link:hover {
	background-color: $gray-10;
}

.c-header__mobile-menu-link.all {
	font-weight: $font-semibold;
	text-decoration: underline;
}

.c-header__mobile-menu-link.all:hover {
	background-color: $gray-10;
}

.c-header__mobile-menu-link .icon {
	width: rem(16);
	height: rem(20);
	margin-right: rem(12);
}

.c-mobile-menu-nav.footer {
	margin: auto 0 0 0;
	padding: rem(16) rem(20);
	background-color: $gray-10;
}

.c-mobile-menu-nav.footer .c-header__mobile-menu-item:last-child {
	margin-bottom: 0;
}

.c-mobile-menu-nav.footer .c-header__mobile-menu-link {
	padding-left: 0;
}


.header-megamenu {
	display: none;
	position: relative;
	z-index: $z-index-8;

	@include breakpoint(min-960) {
		background: $white;
		position: absolute;
		top: rem(156);
		width: 100%;
		left: 0;
		z-index: $z-index-10;
		padding: rem(20) 0 rem(40) 0;
	}


	& > .page-wrap {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		margin: 0 0 rem(40) 0;
		padding: 0;
		list-style-type: none;
		flex: 0 0 50%;

		@include breakpoint(min-768) {
			flex: 0 0 25%;
		}

		@include breakpoint(min-1280) {
			margin: 0 rem(20) 0 0;
			flex: 1 1 rem(160);
			max-width: rem(240);
		}

		h3 {
			font-size: var(--font-size-4);
			line-height: rem(20);
			margin-bottom: rem(16);
			color: $gray-90;
		}

		a {
			text-align: left;
			font-size: var(--font-size-3);
			line-height: rem(32);
			text-decoration: none;
			color: $gray-90;
			font-weight: $font-regular;

			&:hover {
				text-decoration: underline;
			}
		}

		.badge {
			display: inline-block;
			padding: rem(2) rem(6);
			border-radius: rem(2);
			font-size: var(--font-size-1);
			line-height: rem(12);
			text-transform: uppercase;
			font-weight: $font-semibold;
			margin-left: rem(4);
		}

		.sale {
			background-color: $gray-70;
			color: $white;
		}

		.btn {
			color: $white;
			font-size: var(--font-size-2);
			line-height: rem(16);
			font-weight: $font-semibold;
			padding: rem(8) rem(12);
			margin-top: rem(8);
			display: inline-block;
			text-transform: none;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__item--stock a:not(.btn) {
		color: var(--go-green);
		font-weight: var(--font-weight-semibold);
	}

	&__item--promo {
		flex: 0 1 100%;
		margin: 0;
		padding: rem(40) 0 0 0;
		display: flex;
		border-top: rem(1) solid $gray-20;

		@include breakpoint(min-1280) {
			display: block;
			flex: 0 0 rem(400);
			margin: 0 0 0 auto;
			max-width: rem(400);
			border-top: none;
			padding: 0;
		}

		li {
			align-self: center;
			margin: 0;

			&:last-child {
				margin-left: auto;
			}
		}

		a {
			display: block;
			text-shadow: 0 0 rem(10) rgba(0,0,0,0.50);
		}

		figure {
			position: relative;
		}
		
		.btn {
            position: absolute;
            margin: 0;
            left: rem(32);
            bottom: rem(32);
            display: block;
            padding: rem(8) rem(40);
        }

		h4 {
			font-size: var(--font-size-7);
			line-height: rem(32);
			font-family: $font-stack-display;
			color: $white;
			font-weight: $font-regular;
			margin-bottom: rem(16);
			text-shadow: 0 0 rem(10) rgba(0,0,0,0.50);
		}

		span {
			color: $white;
			font-weight: $font-semibold;
			text-transform: uppercase;
			font-size: var(--font-size-2);
			line-height: rem(16);
		}

		&:hover span {
			text-decoration: underline;
		}
	}
	
	&--shadow {
		box-shadow: 0 rem(20) rem(20) -1rem rgba(26,22,18,0.15);
	}

}


.cart-dropdown {
	display: none;
	position: absolute;
	width: rem(360);
	top: rem(60);
	right: 0;
	list-style-type: none;
	margin: 0;
	padding:0;
	background: $white;
	border-radius:  0 0 rem(3) rem(3);
	box-shadow: 0 rem(10) rem(20) 0 rgba(26,22,18,0.15);

	&::before {
		content: "";
		width: 100%;
		height: rem(20);
		display: block;
		position: absolute;
		background: transparent;
		top: - rem(16);
	}

	&__item {
		display: flex;
		padding: rem(16);
		border-bottom: rem(1) solid $gray-20;
		position: relative;

		&:first-child {
			padding: rem(36) rem(16) rem(16) rem(16);
		}

		&:hover {
			background: $gray-10;
		}
	}

	&__heading {
		padding: rem(32) rem(16) rem(20) rem(16);
		text-align: center;
		font-size: var(--font-size-4);
		line-height: rem(24);
		color: $gray-90;
		font-weight: $font-semibold;
	}

	&__item-image {
		flex: 0 0 rem(52);
		align-self: flex-start;
		max-width: rem(52);
		max-height: rem(52);
		margin-right: rem(16);
	}

	&__item-info {
		list-style-type: none;
		margin: 0;
		padding: 0;
		flex: 1 1 100%;
	}

	&__item-link {
		position: static;
		font-size: var(--font-size-3);
		line-height: rem(16);
		margin-bottom: rem(4);

		a {
			color: $gray-90;
		}
	}

	&__link {
		padding: rem(16);
	}

	&__item-count-price {
		display: flex;
		font-size: var(--font-size-3);
		justify-items: center;
		color: $gray-70;

		strong {
			margin-left: auto;
			font-weight: $font-semibold;
			color: $gray-90;
		}
	}

	&__summary {
		padding: rem(20) 0 rem(12) 0;
		text-align: center;
		color: $gray-70;

		strong {
			font-weight: $font-semibold;
			color: $gray-90;
		}
	}
}

@media (any-hover: hover) {
	.c-header__cart-box-item--cart-content:hover .cart-dropdown {
		display: block;
	}
}