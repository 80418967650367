/* ===========================
Header cart
=========================== */

.c-header-cart {
	background-color: var(--white);
	position: relative;
	z-index: $z-index-10;
	box-shadow: 0 rem(10) rem(8) 0 rgba(var(--gray-20), 0.5);
}

.c-header-cart__nav {
	display: flex;
	align-items: center;
	padding: var(--space-4) 0;
}

.c-header-cart__back-btn {
	color: var(--gray-90);
	font-size: var(--font-size-1);
	line-height: rem(36);
	font-weight: var(--font-weight-semibold);
	text-transform: uppercase;
	text-decoration: none;
	background-color: var(--gray-10);
	border-radius: rem(19);
	height: rem(36);
	padding: 0 rem(16) 0 rem(10);
	transition: background-color 0.2s $ease-in-out-quad;

	@include breakpoint(min-960) {
		font-size: var(--font-size-2);
		line-height: rem(44);
		border-radius: rem(22);
		height: rem(44);
		padding: 0 rem(20) 0 rem(16);
	}

	&:hover {
		background-color: var(--gray-20);
	}

	> span {
		display: none;

		@include breakpoint(min-960) {
			display: inline;
		}
	}

	.icon {
		position: relative;
		top: rem(2);
		fill: var(--gray-90);
		width: rem(14);
		height: rem(14);
		transform: scaleX(-1);
		margin-right: rem(6);

		@include breakpoint(min-960) {
			margin-right: rem(10);
		}
	}
}

.c-header-cart__logo {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	.brilianty-logo {
		fill: $gray-90;
		display: block;
		width: rem(160);
		height: rem(60);

		@include breakpoint(min-640) {
			width: rem(164);
			height: rem(40);
		}

		@include breakpoint(min-1280) {
			width: rem(160);
			height: rem(60);
		}
	}

	&:focus .brilianty-logo {
		fill: $gray-70;
		outline: none;
		background-color: $white;
	}
}

.c-header-cart__help-box {
	display: inline-flex;
	align-items: center;
	margin-left: auto;
}

.c-header-cart__help-box-label {
	display: none;
	color: var(--gray-90);
	line-height: rem(20);
	font-weight: var(--font-weight-semibold);
	margin-right: rem(16);

	@include breakpoint(min-960) {
		display: inline;
	}
}

.c-header-cart__help-box-button {
	border: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--gray-90);
	font-size: var(--font-size-3);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-semibold);
	text-decoration: none;
	background-color: var(--gray-10);
	transition: background-color 0.2s $ease-in-out-quad;
	padding: rem(4) rem(12);
	border-radius: var(--border-radius-6);

	&:hover {
		background-color: var(--gray-20);
	}

	.icon {
		position: relative;
		fill: var(--gray-70);
		width: rem(16);
		height: rem(16);
		margin-right: var(--space-1);
	}
}
