/* ===========================
Collapsible
=========================== */

.c-collapsible {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c-collapsible__btn {
  all: inherit;
  border: 0;
  display: flex;
  position: relative;
  width: 100%;
  padding: var(--space-4) var(--space-4) var(--space-4) var(--space-10);
}

.c-collapsible__header {
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-top: var(--space-025) solid var(--gray-30);
}

.c-collapsible__heading {
  flex: 0 0 calc(100% - var(--space-4));
  margin: 0;
  font-size: var(--font-size-3);
  line-height: var(--line-height-2);
  font-family: $font-stack-text;
  font-weight: var(--font-weight-semibold);
}

.c-collapsible__desc {
  margin: 0;
  font-size: var(--font-size-3);
  line-height: var(--line-height-1);
}

.c-collapsible__btn .icon {
  position: absolute;
  left: var(--space-2);
  width: var(--space-5);
  height: var(--space-5);
  fill: var(--gray-90);
}

.c-collapsible__btn .c-collapsible__expand-icon {
  width: var(--space-4);
  height: var(--space-4);
  fill: var(--gray-90);
  position: absolute;
  right: var(--space-2);
}

.c-collapsible__btn:focus .c-collapsible__expand-icon {
  outline: var(--space-05) solid var(--gray-40);
  background-color: var(--gray-10);
  border-radius: var(--border-radius-05);
}

.c-collapsible__btn[aria-expanded="true"] .vert {
  display: none;
}

.c-collapsible__btn[aria-expanded] rect {
  fill: currentColor;
}

.c-collapsible__content {
  padding: 0 var(--space-3) var(--space-3) var(--space-3);
}
