/*
    POPUP style override
 */
.swal2-html-container {
	text-align: left !important;
	padding: 2.5rem !important;
	margin: 0 !important;
}

/*
    Photoswipe style override
*/
.pswp {
	--pswp-bg: #fff !important;
}

.pswp__counter {
	text-shadow: none !important;
	color: var(--gray-70) !important;
}

.pswp__icn {
	fill: var(--gray-50) !important;
	color: var(--white) !important;
	--pswp-icon-stroke-width: 0px !important;
}

.pswp__custom-video-container {
	position: relative;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.pswp__custom-video-container iframe {
	background: #444;
	width: 100%;
	height: 100%;
	max-width: 800px;
	max-height: 600px;
	pointer-events: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.christmas-2022-phase-4 .h3,
.christmas-2022-phase-4 .p-small {
	color: var(--white);
}

.valentine-2023 .h3 {
	display: block;
	max-width: 12ch;
	font-size: var(--font-size-9);
	line-height: var(--line-height-7);
}

.footer-projects .footer-marianne-days-link {
	color: white;
	font-size: var(--font-size-1);
	display: flex;
	align-items: center;
}

.footer-projects .footer-marianne-days-link picture {
	display: block;
	margin-left: var(--space-2);
}

.marianne-2023 figcaption {
	top: rem(80);
}
