/* ===========================
Badges
=========================== */

.badge {
    color: $white;
    font-weight: $font-semibold;
    text-transform: uppercase;
}

.badge--small {
    border-radius: rem(2);
    font-size: var(--font-size-1);
    line-height: rem(16);
    padding: rem(4) rem(12);
}

.badge--tip         { background-color: $indigo; }
.badge--available   { background-color: $go-green; }
.badge--sale,
.badge--gift        { background-color: $paradise-pink; }
.badge--new         { background-color: $sunglow; }       
.badge--gender      { background-color: var(--gray-10); color: var(--gray-70); }


.badge--gender .icon { 
    flex: 0 0 auto;
    fill: var(--gray-70);
    width: rem(16);
    height: rem(16);
}

.badge--gender .icon-gender-men { 
    width: rem(14);
    height: rem(14); 
}

.badge--gender .icon-gender-women { 
    width: rem(11); 
}

.badge--gender .icon-gender-couple { 
    width: rem(20); 
}