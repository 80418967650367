/* ===========================
Add to cart
=========================== */
.c-common-modal--add-to-cart {
	.c-common-modal__content {
		@include breakpoint(min-640) {
			max-width: rem(440);
		}
	}

	.c-common-modal__filters-wrapper {
		background-color: var(--white);
	}

	.add-to-cart-tick {
		border-radius: var(--border-radius-3);
		width: rem(20);
		height: rem(20);
		display: inline-flex;
		flex: 0 0 auto;
		justify-content: center;
		align-items: center;
		background-color: var(--go-green);
		margin: var(--space-1) var(--space-0);
	}

	.add-to-cart-tick .icon {
		flex: 0 0 auto;
		width: rem(12);
		height: rem(12);
		fill: var(--white);
	}
}

.l-add-to-cart-section-wrapper {
	display: flex;
	flex-direction: column;
	padding: var(--space-4);
	gap: var(--space-4);

	@include breakpoint(min-640) {
		padding: var(--space-6);
    }
}

.l-add-to-cart-section-wrapper--cross-sell {
	padding: var(--space-4) var(--space-0);

	@include breakpoint(min-640) {
		padding: var(--space-6) var(--space-0);
    }
}

.l-add-to-cart-section-wrapper--cross-sell .c-add-to-cart-cross-sell-carousel {
	padding: var(--space-0) var(--space-4);

	@include breakpoint(min-640) {
		padding: var(--space-0) var(--space-6);
    }
}

.l-add-to-cart-section-wrapper .header {
	font-size: var(--font-size-5);
	line-height: var(--line-height-4);
	font-weight:  var(--font-weight-semibold);
}

.l-add-to-cart-section-wrapper--cross-sell .header {
	padding:  var(--space-0) var(--space-4);

	@include breakpoint(min-640) {
		padding:  var(--space-0) var(--space-6);
    }
}


.l-add-to-cart-buttons-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: var(--space-4);
}
