/* ===========================
Free delivery progress bar
=========================== */
.c-free-delivery-progress-bar {
	display: flex;
	flex-direction: column;
	gap: var( --space-1);
	width: 100%;
}

.c-free-delivery-progress-bar--success {
	justify-content: center;
	text-align: center;
}

.c-free-delivery-progress-bar__label {
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);

	.bold {
		font-weight: var(--font-weight-semibold);
	}
}

.c-free-delivery-progress-bar__bar {
	border-radius: var(--border-radius-05);
	background-color: var(--gray-30);
	width: 100%;
	height: rem(4);
	display: inline-flex;
	overflow: hidden;
}

.c-free-delivery-progress-bar__status {
	border-radius: var(--border-radius-05);
	background-color: var(--go-green);
	height: rem(4);
}