/* ===========================
Common loader
=========================== */

.c-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    z-index: $z-index-9;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
}

.c-loader img {
    animation: rotate 1s linear infinite; 
}