/* ===========================
Add to cart item
=========================== */
.c-add-to-cart-item {
	display: flex;
	gap: var(--space-3);
	padding: var(--space-4);
	font-size: var(--font-size-4);
	line-height: var(--line-height-3);

	@include breakpoint(min-640) {
		padding: var(--space-6);
    }
}

.c-add-to-cart-item__image {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(var(--taupe-500),.03);
	}
}

.c-add-to-cart-item__image img {
	width: rem(80);
	height: auto;
	display: block;
}

.c-add-to-cart-item__name {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.c-add-to-cart-item__price {
	font-weight: var(--font-weight-semibold);
}

.c-add-to-cart-item__price--sale {
	color: var(--paradise-pink);
}

.c-add-to-cart-item__price-origin {
	color: var(--gray-50);
	font-size: var(--font-size-3);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-regular);
	text-decoration: line-through;
}