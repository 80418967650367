/* ===========================
Cookie bar
=========================== */

.df-cookie-bar {
    background: rgba($gray-80, 0.9);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - rem(40));
    color: $white;
    border-radius: rem(8);
    margin: rem(20);
    padding: rem(32);
    box-shadow: 20px 20px 60px rgba($gray-80, 0.2);

    @include breakpoint(min-1024) {
        flex-direction: row;
    }

}
    
.df-cookie-bar[hidden] {
    display: none;
}

.df-cookie-bar__copy {
    margin: 0 0 rem(20) 0;
    align-self: center;

    @include breakpoint(min-1024) {
        margin: 0 rem(40) 0 0;
    }
}

.df-cookie-bar__copy a {
    color: $white;
}

.df-cookie-bar__choices {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
}

.df-cookie-bar__choice:first-child {
    margin-right: rem(12);
}

.df-cookie-bar__btn {
    display: block;
    font-size: var(--font-size-3);
    line-height: rem(16);
    text-transform: uppercase;
    font-weight: $font-semibold;
    text-decoration: none;
    padding: rem(16) rem(24);
    border-radius: rem(3);
    border: none;
}

.df-cookie-bar__btn--agree {
    background: $white;
    color: $gray-80;
}

.df-cookie-bar__btn--disagree {
    background: transparent;
    color: $white;
    text-decoration: underline;
    text-transform: none;
}