/* ===========================
Breadcrumbs
=========================== */

.c-breadcrumbs {
	list-style-type: none;
	margin: var(--space-5) 0;
	padding: 0;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	text-overflow: ellipsis;
	justify-content: center;
}

.c-breadcrumbs--product {
	justify-content: flex-start;
	
	@include breakpoint(min-640) {
		justify-content: center;
	}
}

.c-breadcrumbs__item {
	flex: 0 0 auto;
	color: var(--gray-90);
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2);
	line-height: var(--line-height-1);
}

.c-breadcrumbs__item::after {
	content: "/";
	font-weight: var(--font-weight-regular);
	margin: 0 var(--space-3);
	color: var(--gray-40);
	display: inline-block;
}

.c-breadcrumbs__item:last-child::after {
	display: none;
}

.c-breadcrumbs__link {
	color: var(--gray-70);
	text-decoration-line: none;
	text-decoration-color: transparent;
	transition: text-decoration-color .2s $ease-in-out-quad, color .2s $ease-in-out-quad;
}

.c-breadcrumbs__link:hover {
	color: var(--gray-90);
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-color: var(--gray-90);
}

@supports (text-decoration-thickness: var(--space-05)) and (text-underline-offset: var(--space-1)) {
	.c-breadcrumbs__link:hover {
		text-decoration-thickness: var(--space-05);
		text-underline-offset: var(--space-1);
	}
}