/* ===========================
Newsletter Signup
=========================== */

.c-nl-signup {
	background-color: $gray-10;
	padding: rem(40) 0 rem(60);
	text-align: center;

	@include breakpoint(min-768) {
		padding: rem(80) 0 rem(100);
	}
}
	
.c-nl-signup__desc {
	display: block;
	margin: 0 auto rem(40);
}
	
.c-nl-signup__form {
	display: flex;
	flex-direction: column;
	margin: 0 auto rem(16);
	max-width: rem(652);
	
	@include breakpoint(min-640) {
		flex-direction: row;
		margin: 0 auto rem(32);
	}
}

.c-nl-signup__form .input {
	box-shadow: 0 0 rem(20) 0 rgba($gray-80, 0.15);
	margin-bottom: rem(8);
	border: none;
	
	@include breakpoint(min-640) {
		flex: 0 0 70%;
		margin: 0;
		border-radius: rem(3) 0 0 rem(3);
	}
}

.c-nl-signup__form .btn {
	@include breakpoint(min-640) {
		flex: 0 0 30%;
	}
}

.c-nl-signup__legal {
	color: $gray-80;
	max-width: 70ch;
	display: block;
	margin: 0 auto;
}

.c-nl-signup__legal a {
	color: $gray-90;
}