/* ===========================
Tokens
=========================== */

:root {
	--space-0: 0;
	--space-025: 1px;
	--space-05: 2px;
	--space-1: 4px;
	--space-2: 8px;
	--space-3: 12px;
	--space-4: 16px;
	--space-5: 20px;
	--space-6: 24px;
	--space-8: 32px;
	--space-9: 36px;
	--space-10: 40px;
	--space-12: 48px;
	--space-16: 64px;
	--space-20: 80px;
	--space-24: 96px;
	--space-28: 112px;
	--space-32: 128px;
	--space-40:	160px;

	--border-radius-05: 2px;
	--border-radius-1: 4px;
	--border-radius-2: 8px;
	--border-radius-3: 12px;
	--border-radius-4: 16px;
	--border-radius-5: 20px;
	--border-radius-6: 30px;
	--border-radius-base: 3px;
	--border-radius-large: 6px;
	--border-radius-half: 50%;

	--font-weight-regular: 400;
	--font-weight-medium:   500;
	--font-weight-semibold: 600;
	--font-weight-bold:     700;

	--font-size-1: 0.75rem; // 12px
	--font-size-2: 0.8125rem; // 13px
	--font-size-3: 0.875rem; // 14px
	--font-size-4: 1rem; // 16px
	--font-size-5: 1.125rem; // 18px
	--font-size-6: 1.25rem; // 20px
	--font-size-7: 1.5rem; // 24px
	--font-size-8: 2rem; // 32px
	--font-size-9: 2.5rem; // 40px
	--font-size-10: 3.25rem; // 52px

	--line-height-1: 1rem; // 16px
	--line-height-2: 1.25rem; // 20px
	--line-height-3: 1.5rem; // 24px
	--line-height-4: 1.75rem; // 28px
	--line-height-5: 2rem; // 32px
	--line-height-6: 2.25rem; // 32px
	--line-height-7: 2.75rem; // 44px
	--line-height-8: 3.75rem; // 60px

	// Colors

	--white: #ffffff;
	--black: #000000;

	--gray-90: #1a1919;
	--gray-80: #2b2a2a;
	--gray-70: #454343;
	--gray-60: #7b7b7b;
	--gray-50: #919191;
	--gray-40: #c4c4c4;
	--gray-30: #dbdbdb;
	--gray-20: #ebebeb;
	--gray-10: #f5f5f5;
	--gray-05: #FCFBFA;

	--pink-90: #63202e;
	--pink-80: #71232a;
	--pink-70: #7a3040; // Danfil Kids Brand #1 color
	--pink-60: #9d5f6c;
	--pink-50: #bd7b86;
	--pink-40: #d69da5;
	--pink-30: #edd5d1;
	--pink-20: #f7e6e3; // Danfil Kids Brand #2 color
	--pink-10: #fff4f2;

	--yellow-80: #4d3f13;
	--yellow-70: #806920;
	--yellow-30: #fff6d9;

	--green-50: #00a000;

	--gold-70: #7c6b58;
	--gold-60: #7f7260;
	--gold-50: #ae906f;
	--gold-40: #b29a83;
	--gold-30: #d1c0b6;
	--gold-20: #f0e6db;

	--indigo: #540d6e;
	--go-green: #0ead69;
	--paradise-pink: #ee4266;
	--sunglow: 255, 210, 63; // #FFD23F

	/* Socials colors */
	--facebook-color: #3b5998;
	--twitter-color: #1d9ad8;
	--instagram-color: #3f729b;
	--pinterest-color: #cc2127;
	--google-plus-color: #cd4c39;
	--youtube-color: #cd201f;

	--white-gold: #d5d5d6;
	--yellow-gold: #e0d0aa;
	--rose-gold: #e7c2a9;

	--sc-white: #fff;
	--sc-pink: #ff94b0;
	--sc-red: #e35185;
	--sc-ruby-red: #be1e2d;
	--sc-purple: #7f519b;
	--sc-dark-blue: #3636a1;
	--sc-light-blue: #8896ff;
	--sc-mint-green: #4fffd7;
	--sc-smaragd-green: #00b364;
	--sc-olive-green: #21e05e;
	--sc-yellow: #fff363;
	--sc-orange: #ff8700;

	--bd-600: 30, 80, 81; // #1E5051
	--bd-500: 52, 97, 98; // #346162

	--taupe-025: 251, 250, 249; // #FBFAF9
	--taupe-050: 240, 237, 233; // #F0EDE9
	--taupe-100: 229, 227, 220; // #E5E3DC
	--taupe-200: 201, 198, 191; // #C9C6BF
	--taupe-300: 173, 168, 158; // #ADA89E
	--taupe-400: 154, 150, 141; // #9A968D
	--taupe-500: 128, 125, 117; // #807D75
	--taupe-600: 111, 109, 103; // #6F6D67
	--taupe-700: 87, 86, 81; // #575651
	--taupe-800: 53, 52, 49; // #353431
	--taupe-900: 21, 21, 20; // #151514

	// Color for image overlay
	--image-background-color: rgba(0,0,0,.02);

	// Box-shadow
	--box-shadow-small: 0 0 40px rgba(57, 54, 52, 0.12);
	--box-shadow-medium: 0 0 20px rgba(57, 54, 52, 0.4);


}
