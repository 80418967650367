/* Base styles */

li, nav, footer, header, section, article {
    position: relative;
}

input, textarea, select, button {
	font-family: $font-stack-text;
}

html {
	height: 100%;
	box-sizing: border-box;
	scroll-behavior: smooth;
}


body {
	font-size: 100%;
	height: 100%;
	font-family: $font-stack-text;
	font-weight: $font-regular;
}

a {
	color: $gray-90;
	font-weight: $font-semibold;
}

::-moz-selection {
	background-color: $gray-20;
	color: $gray-90;
}

::selection {
	background-color: $gray-20;
	color: $gray-90;
}

:focus {
	background-color:$gray-10;
}

.footer *:focus {
	background-color: $gray-70;
}

.clear {
	clear: both;
}

.clearfix::after {
	content: "";
	display: table;
	clear: both;
}

.hidden {
	opacity: 0;
	display: none;
}

.visually-hidden { 
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}

.c-tooltip[role=tooltip],
.hidetooltip + .c-tooltip[role=tooltip] {
	visibility: hidden;
	position: absolute;
	background-color: var(--gray-70);
	border-radius: var(--border-radius-1);
	color: var(--gray-10);
	width: auto;
	white-space: nowrap;
}

.c-tooltip--right-top {
	bottom: 105%;
	right: 0;
}

.c-tooltip--medium {
	padding: rem(2) rem(6);
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
}

[aria-describedby]:hover,
[aria-describedby]:focus {
	position: relative;
}

[aria-describedby]:hover + [role=tooltip],
[aria-describedby]:focus + [role=tooltip] {
	visibility: visible;
}

.show {
	opacity: 1;
	display: block;
}

.screen-reader-text {
	position: absolute;
	top: -9999em;
	left: -9999em;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

.wf-loading {
	opacity: 0;
	visibility: hidden;
}

.ie.wf-loading {
	visibility: hidden;
}

.ie.wf-active {
	visibility: visible;
}

.wf-active {
	visibility: visible;
	opacity: 1;
	transition: opacity .3s linear;
}

.error-message {
	display: block;
	background-color: #EA0944;
	color: #fff;
}


.dropdown {
	display: none;
	display: none;

	&.show {
		display: block;
	}
}

.page-wrap,
.u-page-wrap {
	max-width: $page-context + px;
	padding: 0;
	margin: 0 auto;
	// background: rgba(red, 0.2);
	width: 96%;

	// @include breakpoint(min-480)   	{ background: rgba(fuchsia, 0.2);}
	// @include breakpoint(min-640)   	{ background: rgba(green, 0.2); }
	// @include breakpoint(min-768)   	{ background: rgba(blue, 0.2); }
	// @include breakpoint(min-960) 	{ background: rgba(brown, 0.2);}
	// @include breakpoint(min-1024)  	{ background: rgba(aqua, 0.2); }
	// @include breakpoint(min-1600)  	{ background: rgba(yellow, 0.8); }

}

//Utility class for image gray overlay
.u-image-overlay {
	filter: brightness(.98);
	background-color: var(--white);
}

//New LP page-wrap
.lp-jewelry-guide .page-wrap {
	max-width: rem(1840);
	width: 100%;
	position: relative;
	padding: 0 rem(12);

	@include breakpoint(min-640) {
		padding: 0 rem(24);
	}

	@include breakpoint(min-768) {
		padding: 0 rem(40);
	}

	@include breakpoint(min-1280) {
		padding: 0 rem(60);
	}

	@include breakpoint(min-1600) {
		width: calc(100% - #{rem(160)});
		padding: 0;
	}
}

/* System messages */

.system-message {
	font-size: var(--font-size-3);
	margin: em(5,14) auto;
	padding: em(10,14) 0;
	border-radius: 3px;
	text-align: center;
	color: $white;
}

// TODO colors for state messages
.system-message.error { 
	background-color: #C91F40; 
} 

.system-message.success { 
	background-color: #21AF62; 
}

.system-message.notice 	{ 
	background-color: #E2B01A; 
}

.system-message a {
	color: $white;
	font-weight: $font-semibold;
}

.system-message a:hover {
	text-decoration: none;
}

.message {
	background: blue;
	display: block;
	text-align: center;
	border-radius: rem(3);
	font-weight: $font-semibold;
	margin: 0;
	padding: 0;
}

.message--small {
	font-size: var(--font-size-3);
	line-height: rem(16);
	padding: rem(8);
}

.message--info {
	background: $sunglow;
	color: #4D3F13; 
}

.message--error {
	background-color: $paradise-pink;
	color: $white;
}

.responsive-ads {
    display: none;
}

.default-popup {
	position: relative;
	padding: 60px;
	width: auto;
	max-width: 800px;
	border-radius: 3px;
	margin: auto auto;
}

[hidden] {
	display: none;
}

.overflow-hidden {
    overflow: hidden;
}

.tooltip-container button {
	background-color: transparent;
}

.tooltip-container .icon {
	fill: $gray-50;
}


.default-page-content {
	background-color: $gray-10;
	position: relative;
	padding-bottom: rem(40);
}

.default-page-content .breadcrumbs {
	display: flex;
	width: 100%;
	max-width: 100%;
	position: absolute;
	justify-content: center;
	top: rem(4);

	@include breakpoint(min-768)	{ top: rem(8); }
	@include breakpoint(min-960) 	{ top: rem(12); }
	@include breakpoint(min-1280) 	{ top: rem(16); }
	@include breakpoint(min-1600) 	{ top: rem(20); }
}

.default-page-content .breadcrumbs li {
	color: $gray-70;	
}

.default-page-content .page-wrap {
	background: $white;
	padding: rem(32);
	border-radius: rem(3);

	@include breakpoint(min-768)	{ padding: rem(40); }
	@include breakpoint(min-960) 	{ padding: rem(44); }
	@include breakpoint(min-1280) 	{ padding: rem(52); }
	@include breakpoint(min-1600) 	{ padding: rem(60); }
}



.default-page-content h1 {
	padding: rem(40) 0 rem(32) 0;
	max-width: 70ch;
	display: block;
	text-align: center;

	@include breakpoint(min-768) {
		padding: rem(52) 0 rem(44) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(60) 0 rem(52) 0;
	}

	@include breakpoint(min-1280) {
		padding: rem(72) 0 rem(60) 0;
	}

	@include breakpoint(min-1600) {
		padding: rem(88) 0 rem(80) 0;
	}

}

.default-page-content h2 {
	max-width: 70ch;

	@include breakpoint(min-768) {
		margin: rem(24) 0 rem(16) 0;
	}
	
	@include breakpoint(min-960) {
		margin: rem(28) 0 rem(20) 0;
	}

	@include breakpoint(min-1280) {
		margin: rem(32) 0 rem(24) 0;
	}

	@include breakpoint(min-1600) {
		margin: rem(36) 0 rem(28) 0;
	}
}

.default-page-content h3 {
	margin: rem(16) 0 rem(8) 0;
	max-width: 70ch;
}

.default-page-content h4 {
	font-size: var(--font-size-1);
	text-transform: uppercase;
	line-height: rem(14);
	margin: rem(12) 0 rem(4) 0;
	max-width: 70ch;

	@include breakpoint(min-768) {
		font-size: var(--font-size-3);
		line-height: rem(16);
		margin: rem(16) 0 rem(8) 0;
	}
	
	@include breakpoint(min-960) {
		font-size: var(--font-size-4);
		line-height: rem(20);
		margin: rem(20) 0 rem(12) 0;
	}

	@include breakpoint(min-1280) {
		font-size: var(--font-size-6);
		line-height: rem(24);
		margin: rem(24) 0 rem(16) 0;
	}

	@include breakpoint(min-1600) {
		font-size: var(--font-size-7);
		line-height: rem(28);
		margin: rem(28) 0 rem(20) 0;
	}
}

.default-page-content ul {
	color: $gray-70;
	max-width: 70ch;
}

.default-page-content p {
	color: $gray-70;
	max-width: 70ch;
}

.default-page-content p:first-of-type {
	font-size: var(--font-size-5);
}

.default-page-content p + p {
	margin: 0 0 rem(16) 0;
}

.default-page-content a {
	color: $gray-90;
	font-weight: $font-semibold;
}



.default-page-content th,
.default-page-content tr:first-of-type td {
	background-color: $gray-20;
	color: $gray-50;
	font-weight: $font-semibold;
	text-transform: uppercase;
	font-size: var(--font-size-2);
}

.default-page-content td {
	border: rem(1) solid $gray-20;
	padding: rem(12);
}

.default-page-content fieldset {
	margin: rem(20) 0 rem(40) 0;
	padding: rem(20);
	background: $gray-10;
	max-width: 100%;
}

.default-page-content input[type="text"],
.default-page-content input[type="email"],
.default-page-content input[type="search"],
.default-page-content input[type="url"],
.default-page-content input[type="password"],
.default-page-content input[type="tel"],
.default-page-content input[type="number"] {
	background: $white;
	border: rem(1) solid $gray-50;
	border-radius: rem(2);
	font-size: var(--font-size-4);
	padding: rem(12);
	line-height: rem(16);
	display: block;
	margin-bottom: rem(12);
	width: 100%;
	max-width: rem(400);
}

.default-page-content input[type="submit"] {
	text-align: center;
	display: block;
	font-weight: $font-semibold;
	text-transform: uppercase;
	text-decoration: none;
	transition: background-color .2s $ease-in-out-quad, box-shadow .2s $ease-in-out-quad, color .2s $ease-in-out-quad;
	border: rem(2) solid $gray-90;
	background: $gray-90;
	color: $white;
	font-size: var(--font-size-3);
	line-height: rem(44);
	width: 100%;
	max-width: rem(260);
}

.main-content {
	background: $white;
}

.main-content::before {
	position: fixed;
	background: $black;
	bottom: - 100%;
	left: 0;
	top: 0;
	right: 0;
	display: block;
	z-index: $z-index-9;
	content: '';
	opacity: 0;
	pointer-events: none;
	transition: opacity $ease-in-out-quad .2s;
}

.main-content--active::before {
	position: absolute;
	opacity: 0.5;	
}

.responsive-ads {
	padding: rem(20) 0;
}

.responsive-ads img {
	display: block;
	max-width: 100%;
	margin: 0 auto;
}

.nl-container {
	background: $white;
	margin: rem(20) 0;
	padding: rem(20);
	border: rem(10) solid $gray-10;
}

.nl-container__header {
	max-width: 75ch;
	margin: 0 0 rem(8) 0;
}

.nl-container__desc {
	max-width: 75ch;
	margin: 0 0 rem(32) 0;
}

.nl-container__form {
	display: flex;
	flex-direction: column;

	@include breakpoint(min-768) {
		flex-direction: row;
		align-items: flex-end;
	}
}

.nl-container__form .c-input-label {
	display: block;
	width: 100%;
	max-width: rem(400);
	margin-bottom: rem(8);

	@include breakpoint(min-768) {
		margin-bottom: 0;
	}
}

.nl-container__form label {
	font-size: var(--font-size-3);
}

.nl-container__form input[type=submit] {
	width: 100%;
	max-width: rem(260);

	@include breakpoint(min-768) {
		margin: rem(22) 0 0 rem(8);
	}
	
}

.nl-container__notice {
	font-size: var(--font-size-3);
	line-height: rem(16);
	color: $gray-50;
	margin-top: rem(20);
	padding: 0;
	display: block;
}

.hexa-link {
	background: $gray-20;
	padding: rem(6) rem(12);
	line-height: rem(16);
	font-size: var(--font-size-3);
	border-radius: rem(3);
	margin-bottom: rem(20);
	display: inline-block;
}

.hexa-link:hover {
	background: $gray-30;
}

.arrow-link {
	color: $gray-90;
	box-shadow: 0 rem(1) 0 0 $gray-90;
	text-decoration: none;
	font-size: var(--font-size-3);
	line-height: rem(28);
	text-transform: uppercase;
	font-weight: $font-semibold;
	position: relative;
	transition: box-shadow .2s $ease-in-out-quad;
}

.arrow-link:hover {
	box-shadow: 0 rem(2) 0 0 $gray-90;
}

.arrow-link:hover .icon-arrow-right {
	transform: translateX(rem(6));
}

.arrow-link .icon-arrow-right {
	position: absolute;
	top: rem(2);
	right: - rem(16);
	display: block;
	width: rem(12);
	height: rem(12);
	transition: transform .2s $ease-in-out-quad;
}

.screenreader-text, .main-content-anchor {
	position: absolute;
	left: -999px;
	width: 1px;
	height: 1px;
}
.screenreader-text:focus, .screenreader-text:active {
	left: rem(16);
	top: 0;
	height: auto;
	width: auto;
	font-size: var(--font-size-5);
	color: $gray-90;
	padding: rem(12) rem(20);
	display: block;
	z-index: 10000;
	background-color: $white;
	box-shadow: 0 0 20px rgba($gray-80,.2);
	border-radius: 0 0 rem(3) rem(3);
}

.hexa-ribbon {
	background-color: $yellow-30;
	color: $yellow-80;
	padding: rem(16);
	display: block;
	z-index: $z-index-10;
	text-align: center;
}

.product-detail__general-error {
	width: 100%;
	margin: rem(4) 0 0 0;
	font-weight: var(--font-weight-semibold);
	color: var(--paradise-pink) !important;
	font-size: var(--font-size-3);
	line-height: rem(20);
}