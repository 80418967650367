/* ===========================
Common modal
Usage: Filter, Add to cart
=========================== */
.c-common-modal {
    position: fixed;
    top: 0;
	left: 0;
    z-index: $z-index-12;
    width: 100%;
    height: 100%;
    display: flex;
	justify-content: flex-end;
    align-items: flex-end;
	background-color: rgba(28,27,26,.7);
}

.c-common-modal.hidden {
    clip: rect(0 0 0 0); 
  	height: 1px;
  	overflow: hidden;
  	position: absolute;
  	white-space: nowrap; 
  	width: 1px;
}

.c-common-modal.hidden .c-common-modal__wrapper {
    transform: translateY(100%);

	@include breakpoint(min-640) {
		transform: translateY(0);
		transform: translateX(100%);
    }
}

.c-common-modal__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	transform: translateX(0), translateY(0);
	transition: transform .2s $ease-in-out-quad;

	@include breakpoint(min-640) {
		flex-direction: row;
		margin-left: auto;
		align-items: flex-start;
    }
}

.c-common-modal__close-wrapper {
	display: flex;
	justify-content: flex-end;
	margin: var(--space-4);
}

.c-common-modal__close {
	flex: 0 0 auto;
	border-radius: var(--border-radius-half);
	background-color: var(--white);
	width: rem(36);
	height: rem(36);
	border: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: box-shadow .2s $ease-in-out-quad, background-color .2s $ease-in-out-quad;

	.icon {
		flex: 0 0 auto;
		fill: var(--gray-70);
		width: rem(14);
		height: rem(14);
		transition: fill .2s $ease-in-out-quad;
	}

	&:hover {
		background-color: var(--gray-20);

		.icon {
			fill: var(--gray-60);
		}
	}

	&:focus, &:focus-visible {
		outline: none;
		box-shadow: inset 0 0 0 rem(1) var(--gray-60), 0 0 0 rem(3) var(--gray-20);
	}
}

.c-common-modal__content {
	border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
	width: 100%;
	max-height: calc(100% - #{rem(68)});
	box-shadow: var(--box-shadow-small);  
    
    @include breakpoint(min-640) {
		border-radius: 0;
		max-width: rem(360);
		height: 100%;
		max-height: none;
    }
}

.c-common-modal__content > *:first-child {
	border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
}


.c-common-modal__header-wrapper {
	display: flex;
	align-items: center;
	padding: var(--space-4);
	gap: var(--space-4);
	border-bottom: rem(1) solid var(--gray-20);
	background-color: var(--white);

	@include breakpoint(min-640) {
		border-radius: 0;
		padding: var(--space-6);
    }
}

.c-common-modal__header-label {
	font-size: var(--font-size-5);
	line-height: var(--line-height-4);
	font-weight:  var(--font-weight-semibold);
	display: inline-flex;
	gap: var(--space-2);
}

.c-common-modal__header-filter-cancel {
	margin-left: auto;
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	font-weight:  var(--font-weight-regular);
}

// TODO: need to unify name class with filter popup -> .c-common-modal__content-wrapper
.c-common-modal__filters-wrapper {
	height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: var(--space-2);
	background-color: #FCFBFA; //050

	@include breakpoint(min-640) {
		gap: var(--space-0);
		background-color: var(--white);
    }
}

.c-common-modal__footer-wrapper {
	display: flex;
	align-items: center;
	padding: var(--space-4);
	gap: var(--space-4);
	border-top: rem(1) solid var(--gray-20);
	background-color: var(--white);

	@include breakpoint(min-640) {
		padding: var(--space-6);
    }
}