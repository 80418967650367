// Component - Amount input

.c-amount-input {
	border-radius: var(--border-radius-1);
	display: inline-flex;
}

.c-amount-input__label {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.c-amount-input__button {
	border: var(--space-025) solid var(--gray-30);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: var(--white);
	height: 100%;
	border-radius: 0;
	transition: background-color 0.2s $ease-in-out-quad;
}

.c-amount-input__button:hover {
	background-color: var(--gray-20);
}

.c-amount-input__button:focus {
	outline: none;
}

.c-amount-input__button--left {
	border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
	border-right: none;
}

.c-amount-input__button--right {
	border-radius: 0 var(--border-radius-1) var(--border-radius-1) 0;
	border-left: none;
}

.c-amount-input__button .icon {
	fill: var(--gray-70);
}

.c-amount-input__input {
	border-radius: 0;
	border: var(--space-025) solid var(--gray-30);
	border-left-color: transparent;
	border-right-color: transparent;
	height: 100%;
	text-align: center;
}

// Chrome, Safari, Edge, Opera remove Arrows
.c-amount-input__input::-webkit-outer-spin-button,
.c-amount-input__input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.c-amount-input__input:focus {
	z-index: 1;
}

// Sizes
.c-amount-input--medium {
	height: rem(44);
}

.c-amount-input--medium .c-amount-input__button {
	width: rem(44);
}

.c-amount-input--medium .c-amount-input__button .icon {
	width: var(--space-3);
	height: var(--space-3);
}

.c-amount-input--medium .c-amount-input__input {
	width: rem(24);
}