/* ===========================
Footer
=========================== */

.footer {
    background-color: $gray-90;
    padding: rem(40) 0 rem(60);

    h3 {
        color: $gray-50;
        font-weight: $font-semibold;
        text-transform: uppercase;
    }
}

.footer-contact {
    margin-bottom: rem(36);

    @include breakpoint(min-768) {
        margin-bottom: rem(60);
    }

    @include breakpoint(min-1280) {
        width: 40%;
        float: right;
    }

    h3 {
        text-align: center;
        margin-bottom: rem(4);
        font-size: var(--font-size-2);

        @include breakpoint(min-1280) {
            text-align: left;
        }
    }

    p {
        line-height: rem(16);
        font-size: var(--font-size-2);
        color: $gray-50;
        text-align: center;
        margin: 0 0 rem(16) 0;

        @include breakpoint(min-1280) {
            text-align: left;
        }
    }

    .phone-mail {
        font-size: var(--font-size-3);
	line-height: rem(16);
	color: var(--gray-50);
	text-align: center;
	margin: rem(16) auto rem(16);
    padding: 0;

	@include breakpoint(min-1024) {
		text-align: left;
	}
        
    }

    ul {
        list-style-type: initial;
        font-size: var(--font-size-3);
        line-height: rem(16);
        color: var(--gray-50);
        text-align: center;
        margin: 0 auto rem(32);

        @include breakpoint(min-1024) {
            text-align: left;
        }
    }

    li {
        line-height: rem(24);
    }

    .phone-mail li {
        width: 100%;
        padding: rem(8) 0;
        
        
        @include breakpoint(min-480) {
            padding: 0;
            width: auto;
            display: inline-block;
        }

        &:first-child {
            margin-right: rem(20);
        }
    }

    .icon {
        fill: $white;
        width: rem(16);
        height: rem(16);
        margin-right: rem(12);

        @include breakpoint(min-1600) {
            width: rem(20);
            height: rem(20);
        }
    }

    a {
        color: $white;
        font-weight: $font-semibold;
        line-height: rem(16);
        text-decoration: none;
        
        @include breakpoint(min-1600) {
            font-size: var(--font-size-6);
            line-height: rem(20);
        }

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        color: var(--white);
    }

}

.footer-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content:center;
    margin-bottom: rem(20);
    border-bottom: rem(1) solid $gray-70;

    @include breakpoint(min-1280) {
        width: 60%;
        float: left;
        border-bottom: none;
    }

    ul {
        margin: 0 0 rem(36) 0;
        padding: 0;
        list-style-type: none;
        flex: 1 0 rem(170);

        @include breakpoint(min-480) {
            min-width: rem(220);
        }

        @include breakpoint(min-640) {
            min-width: rem(170);
        }
    }

    h3 {
        margin-bottom: rem(16);
        font-size: var(--font-size-2);

        a {
            text-decoration: none;
            color: $gray-50;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    li {

        > a {
            padding-left: rem(20);
            color: $white;
            text-decoration: none;
            font-size: var(--font-size-3);
            line-height: rem(32);
            position: relative;

            &::before {
                content: "";
                display: block;
                width: rem(8);
                height: rem(1);
                position: absolute;
                left: 0;
                top: rem(6);
                background-color: $gray-70;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .socials {

        a {
            padding-left: 0;

            &::before {
                display: none;
            }
        }

        .icon {
            width: rem(16);
            height: rem(16);
            fill: $gray-50;
            margin: rem(8) rem(12) 0 0;
        }

    }
}

.footer-projects {

    @include breakpoint(min-1280) {
        width: 40%;
        float: right;
    }

    h3 {
        text-align: center;
        margin-bottom: rem(24);

        @include breakpoint(min-1280) {
            text-align: left;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0 0 rem(32) 0;
        display: flex;
        justify-content:center;
    }

    li {
        flex: 1 1 rem(104);
        align-self: center;
        margin-right: rem(16);

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: block;
        padding: 0 rem(16);

        svg {
            transition: fill .2s $ease-in-out-quad;
        }

        @include breakpoint(min-1280) {
            padding: 0 rem(32) 0 0;
        }

        &:hover svg {
            fill: $white;
        }
    }

    .danfil-kids-logo,
    .danfil-wedding-rings-logo,
    .detske-ousko-logo {
        fill: $gray-30;
        max-width: rem(200);
        max-height: rem(80);
        display: block;
        margin: 0 auto;
    }

}

.footer-bottom-row {
    border-top: rem(1) solid $gray-70;
	padding-top: rem(20);
	clear: both;
    
    @include breakpoint(min-1280) {
        clear: both;
    }

}

.footer-language-switch {
    background-color: $gray-80;
    margin-bottom: rem(20);
    position: relative;

    @include breakpoint(min-480) {
        float: left;
        margin-right: rem(16);
    }

    .icon-flag {
        width: rem(16);
        height: rem(12);
        position: absolute;
        top: rem(10);
        left: rem(8);
    }

    .icon-chevron-bottom {
        fill: $gray-50;
        width: rem(8);
        height: rem(8);
        position: absolute;
        right: rem(8);
        top: rem(12);
    }

    &__button {
        width: 100%;
        border: none;
        background-color: $gray-80;
        border-radius: rem(2);
        padding: rem(8) 0 rem(8) rem(36);
        position: relative;
        color: $white;
        font-size: var(--font-size-2);
        line-height: rem(16);
        text-transform: uppercase;
        font-weight: $font-semibold;
        text-align: left;
        transition: background-color .2s $ease-in-out-quad;

        &:hover {
            background-color: $gray-70;
        }

        @include breakpoint(min-480) {
            padding: rem(8) rem(24) rem(8) rem(36);
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        bottom: calc(100% + 4px);
        width: 100%;
        border-radius: rem(2);
        z-index: $z-index-10;
        background-color: $gray-80;

        @include breakpoint(min-480) {
            position: absolute;
        }
    }

    &__list-item {
        transition: background-color .2s $ease-in-out-quad;

        a {
            display: block;
            color: $white;
            padding: rem(8) 0 rem(8) rem(36);
            font-size: var(--font-size-2);
            line-height: rem(16);
            text-transform: uppercase;
            font-weight: $font-semibold;
            text-align: left;
            text-decoration: none;
        }

        &:hover {
            background-color: $gray-70;
        }
    }

}

.footer-bottom-links {
    list-style-type: none;
    margin: 0 0 rem(60) 0;
    padding: 0;

    @include breakpoint(min-480) {
        float: left;
    }

    @include breakpoint(min-1024) {
        margin-bottom: 0;
    }

    li {
        padding: rem(8);
        line-height: rem(16);

        @include breakpoint(min-480) {
            display: inline-block;
            padding: rem(8) 0;
            margin-right: rem(8);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        font-size: var(--font-size-2);
        color: $gray-50;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer-copyright {
    text-align: center;
    color: $gray-50;
    font-size: var(--font-size-2);
    line-height: rem(16); 

    @include breakpoint(min-480) {
        clear: both;
    }

    @include breakpoint(min-1024) {
        clear: none;
        float: right;
    }
    
    span {
        display: none;

        @include breakpoint(min-480) {
            display: inline;
        }
    }
}

.footer-eet {
    background: #000;
    display: block;
    width: 100%;
    max-width: 100%;
    text-align: center;
    color: $gray-50;
    font-size: var(--font-size-2);
    line-height: rem(16); 
	padding: rem(16) 0;
	margin: 0;

    @include breakpoint(min-1024) {
        clear: none;
        float: right;
    }

}


.c-footer__contact {
	margin-bottom: rem(36);

	@include breakpoint(min-1024) {
		flex: 0 0 35%;
		margin-bottom: rem(52);
	}
}

.c-footer__contact h3 {
	text-align: center;
	margin-bottom: rem(4);

	@include breakpoint(min-1024) {
		text-align: left;
	}
}
	
.c-footer__contact p {
	font-size: var(--font-size-3);
	line-height: rem(16);
	color: var(--gray-50);
	text-align: center;
	margin: 0 auto rem(16);

	@include breakpoint(min-1024) {
		text-align: left;
	}
}

.c-footer__contact ul {
	font-size: var(--font-size-3);
	line-height: rem(16);
	color: var(--gray-50);
	text-align: center;
	margin: 0 auto rem(16);

	@include breakpoint(min-1024) {
		text-align: left;
	}
}

.c-footer__contact li {
	line-height: rem(24);
}